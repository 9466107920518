import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";

import NavBar from "../components/NavBar/Navbar";
import Styles from "../components/Styles/Styles.module.css"; 
import TabTitle from "../components/Utilities/FunctionTitle";

function Contact() {
    const {t, i18n} = useTranslation();

    useEffect(() => {
        const lng = navigator.language;
        i18n.changeLanguage(lng);
      }, [])   

    TabTitle("Contact | digitaltrip.net | Boost your business");

    return (
        <div>
            <NavBar></NavBar>     
            <div className="divPageTop">    
            </div>
            <div className={Styles.divContent}>
                <h1>
                {t("contact.title")}
                </h1>
                <p>
                {t("contact.phone")}: <br></br>
                <ul>
                <li>
                    Colombia: <a target="_blank" href='https://api.whatsapp.com/send/?phone=17864750772&text=Hola+digitaltrip.net+Quiero+potenciar+mi+negocio'>
                            +1 (786) 475-0772
                        </a>
                        <a target="_blank" title="Escríbenos por Whatsapp - digitaltrip.net" href="https://api.whatsapp.com/send/?phone=17864750772&text=Hola+digitaltrip.net+Quiero+potenciar+mi+negocio">
                            <span className="fa-stack fa-lg">
                                <i className="fa fa-whatsapp fa-stack-1x"></i>
                            </span>
                        </a>      
                        <a target="_blank" title="Call us - digitaltrip.net" href="tel:17864750772">
                            <span className="fa-stack fa-lg">
                                <i className="fa fa-phone fa-stack-1x"></i>
                            </span>
                        </a>                                                               
                    </li>    
                    <li>
                    Peru: <a target="_blank" href='https://api.whatsapp.com/send/?phone=51946247689&text=Hola+digitaltrip.net+Quiero+potenciar+mi+negocio'>
                        +51 (946) 247-689
                        </a>                        
                        <a target="_blank" title="Escríbenos por Whatsapp - digitaltrip.net" href="https://api.whatsapp.com/send/?phone=51946247689&text=Hola+digitaltrip.net+Quiero+potenciar+mi+negocio">
                            <span className="fa-stack fa-lg">
                                <i className="fa fa-whatsapp fa-stack-1x"></i>
                            </span>
                        </a>    
                        <a target="_blank" title="Llámanos - digitaltrip.net" href="tel:51946247689">
                            <span className="fa-stack fa-lg">
                                <i className="fa fa-phone fa-stack-1x"></i>
                            </span>
                        </a>                                                  
                    </li>                                      
                    <li>
                    USA: <a target="_blank" href='https://api.whatsapp.com/send/?phone=17864750772&text=Hello+digitaltrip.net+I+want+to+boost+my+business'>
                            +1 (786) 475-0772
                        </a>      
                        <a target="_blank" title="Chat us via Whatsapp - digitaltrip.net" href="https://api.whatsapp.com/send/?phone=17864750772&text=Hello+digitaltrip.net+I+want+to+boost+my+business">
                            <span className="fa-stack fa-lg">
                                <i className="fa fa-whatsapp fa-stack-1x"></i>
                            </span>
                        </a>      
                        <a target="_blank" title="Call us - digitaltrip.net" href="tel:7864750772">
                            <span className="fa-stack fa-lg">
                                <i className="fa fa-phone fa-stack-1x"></i>
                            </span>
                        </a>                                                                        
                    </li>                                      
                </ul>
                </p>     
                <p>
                Email: info@digitaltrip.net
                    <a target="_blank" title="Email us - digitaltrip.net" href="mailto:info@digitaltrip.net">
                        <span className="fa-stack fa-lg">
                            <i className="fa fa-envelope fa-stack-1x"></i>
                        </span>
                    </a>                  
                </p>                   
                <p>
                {t("contact.address")}: 3270 west trade ave, 33133, Miami, Florida, United States
                </p>    

                <h1>
                {t("contact.titleTechnical")}:
                </h1>
                <p>
                {t("contact.phone")}: <br></br>
                <ul>
                    <li>
                        <a target="_blank" href='https://api.whatsapp.com/send/?phone=17864750772&text=Hola+digitaltrip.net+Quiero+potenciar+mi+negocio'>
                            +1 (786) 475-0772
                        </a>
                        <a target="_blank" title="Escríbenos por Whatsapp - digitaltrip.net" href="https://api.whatsapp.com/send/?phone=17864750772&text=Hola+digitaltrip.net+Quiero+potenciar+mi+negocio">
                            <span className="fa-stack fa-lg">
                                <i className="fa fa-whatsapp fa-stack-1x"></i>
                            </span>
                        </a>      
                        <a target="_blank" title="Call us - digitaltrip.net" href="tel:17864750772">
                            <span className="fa-stack fa-lg">
                                <i className="fa fa-phone fa-stack-1x"></i>
                            </span>
                        </a>                                                               
                    </li>                                    
                </ul>
                </p>       

                <h1>
                {t("contact.titleRemoteConnection")}:
                </h1>
                <p>
                {t("contact.titleRemoteConnectionDownload")} AnyDesk:&nbsp; 
                        <a target="_blank" className={Styles.buttonDetails} title="Download AnyDesk" href="https://download.anydesk.com/AnyDesk.exe">
                        {t("general.here")}
                        </a>                   
                        <a target="_blank" title="Download AnyDesk" href="https://download.anydesk.com/AnyDesk.exe">
                            <span className="fa-stack fa-lg">
                                <i className="fa fa-desktop fa-stack-1x"></i>
                            </span>
                        </a>   
                </p>       

                <h1>
                {t("support.knowledgeTitle")}:
                </h1>
                <p>
                {t("support.youtube")} &nbsp;
                        <a target="_blank" className={Styles.buttonDetails} title="Youtube channel" href="https://www.youtube.com/@digitaltrip.net_">
                        {t("general.here")}
                        </a>  
                </p>                                                                           
            </div>
            <div className="divPageTop">   
            </div>            
        </div>
    )
}

export default Contact