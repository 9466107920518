import React, { useEffect } from "react";
import { Link } from 'react-router-dom';
import {useTranslation} from 'react-i18next'

import NavBar from "../components/NavBar/Navbar";
import Styles from "../components/Styles/Styles.module.css"; 
import TabTitle from "../components/Utilities/FunctionTitle";

function Usa() {

    const {t, i18n} = useTranslation();

    useEffect(() => {
        const lng = navigator.language;
        i18n.changeLanguage(lng);
      }, [])   
      
      
    TabTitle("United States of America | digitaltrip.net | Boost your business");
    return (
        <div>
            <NavBar></NavBar>     
            <div className="divPageTop">    
            </div>
            <div className={Styles.divContent}>
                <h1>
                {t("infoUsa.title")}
                </h1>
                <p>
                {t("infoUsa.p1")}
                </p>                             
                <p>
                {t("contact.phone")}: 
                        <a target="_blank" href='https://api.whatsapp.com/send/?phone=573175030091&text=Hello+digitaltrip.net+I+want+to+boost+my+business'>
                            +1 (786) 475-0772
                        </a>      
                        <a target="_blank" title="Chat us via Whatsapp - digitaltrip.net" href="https://api.whatsapp.com/send/?phone=573175030091&text=Hello+digitaltrip.net+I+want+to+boost+my+business">
                            <span className="fa-stack fa-lg">
                                <i className="fa fa-whatsapp fa-stack-1x"></i>
                            </span>
                        </a>      
                        <a target="_blank" title="Call us - digitaltrip.net" href="tel:7864750772">
                            <span className="fa-stack fa-lg">
                                <i className="fa fa-phone fa-stack-1x"></i>
                            </span>
                        </a>                      
                </p>
                <p>
                {t("contact.address")}: 8317 Narcoossee Rd, 32827, Orlando, Florida, United States
                </p>                 
                <p>&nbsp;</p>  
                <p>
                 <Link className={Styles.buttonContact} to={"/contact"}>{t("general.contactUs")}</Link>
                </p>                   
            </div>
            <div className="divPageTop">   
            </div>            
        </div>
    )
}

export default Usa