import React, { useEffect } from "react";
import { Link } from 'react-router-dom';
import {useTranslation} from 'react-i18next'

import NavBar from "../components/NavBar/Navbar";
import Styles from "../components/Styles/Styles.module.css"; 
import TabTitle from "../components/Utilities/FunctionTitle";

function Colombia() {

    const {t, i18n} = useTranslation();

    useEffect(() => {
        const lng = navigator.language;
        i18n.changeLanguage(lng);
      }, [])   
      
      
    TabTitle("Colombia | digitaltrip.net | Boost your business");
    return (
        <div>
            <NavBar></NavBar>     
            <div className="divPageTop">    
            </div>
            <div className={Styles.divContent}>
                <h1>
                {t("infoColombia.title")}
                </h1>
                <p>
                {t("infoColombia.p1")}
                </p>
                <p>
                {t("infoColombia.p2")}
                </p>
                <p>
                {t("infoColombia.p3")}
                </p>                                
                <p>
                {t("contact.phone")}: 
                <a target="_blank" href='https://api.whatsapp.com/send/?phone=17864750772&text=Hola+digitaltrip.net+Quiero+potenciar+mi+negocio'>
                            +1 (786) 475-0772
                        </a>
                        <a target="_blank" title="Escríbenos por Whatsapp - digitaltrip.net" href="https://api.whatsapp.com/send/?phone=17864750772&text=Hola+digitaltrip.net+Quiero+potenciar+mi+negocio">
                            <span className="fa-stack fa-lg">
                                <i className="fa fa-whatsapp fa-stack-1x"></i>
                            </span>
                        </a>      
                        <a target="_blank" title="Call us - digitaltrip.net" href="tel:+17864750772">
                            <span className="fa-stack fa-lg">
                                <i className="fa fa-phone fa-stack-1x"></i>
                            </span>
                        </a>                    
                </p>
                <p>&nbsp;</p>  
                <p>
                 <Link className={Styles.buttonContact} to={"/contact"}>{t("general.contactUs")}</Link>
                </p>                   
            </div>
            <div className="divPageTop">   
            </div>            
        </div>
    )
}

export default Colombia