import React, { useEffect } from "react";
import { Link } from 'react-router-dom';
import {useTranslation} from 'react-i18next'

import NavBar from "../components/NavBar/Navbar";
import Styles from "../components/Styles/Styles.module.css"; 
import TabTitle from "../components/Utilities/FunctionTitle";

function News() {

    const {t, i18n} = useTranslation();

    useEffect(() => {
        const lng = navigator.language;
        i18n.changeLanguage(lng);
      }, [])   

    TabTitle("News | digitaltrip.net | Boost your business");
    return (
        <div>
            <NavBar></NavBar>     
            <div className="divPageTop">    
            </div>
            <div className={Styles.divContent}>
                <h1>{t("news.title")}</h1>
                <p>{t("news.p1")}</p>    

                <div  className={Styles.divNewsContainer}>
                    <div className={Styles.divNewsMainImage}>
                        <img className={Styles.imgMainNewsCarrousel} alt="img news" data-rjs="2" src="videos/roles.png"></img> 
                    </div>                
                    <div className={Styles.divNewsMainContent}>
                        <p>
                        {t("news.post0221Title")}
                        </p>
                        <p>
                        {t("news.viewMore")}<Link className={Styles.buttonDetails} to={"/ever_wonder__who_brings_your_favorite_apps_to_life"}>{t("general.viewMore")}</Link>
                        </p>     
                    </div>
                </div>                   

                <div  className={Styles.divNewsContainer}>
                    <div className={Styles.divNewsMainImage}>
                        <img className={Styles.imgMainNewsCarrousel} alt="img news" data-rjs="2" src="videos/dream_app_full.png"></img> 
                    </div>             
                    <div className={Styles.divNewsMainContent}>
                        <p>
                        {t("news.post0220Title")}
                        </p>
                        <p>
                        {t("news.viewMore")}<Link className={Styles.buttonDetails} to={"/ever_dream_of_an_app_that_solves_your_problem"}>{t("general.viewMore")}</Link>
                        </p>     
                    </div>
                </div>                     

                <div  className={Styles.divNewsContainer}>   
                    <div className={Styles.divNewsMainImage}>
                        <img className={Styles.imgMainNewsCarrousel} alt="img news" data-rjs="2" src="videos/stuck_in_a_tech_rut.png"></img> 
                    </div>                                  
                    <div className={Styles.divNewsMainContent}>
                        <p>
                        {t("news.post0219ContentP1")}
                        </p>
                        <p>
                        {t("news.viewMore")}<Link className={Styles.buttonDetails} to={"/stuck_in_a_tech_rut"}>{t("general.viewMore")}</Link>
                        </p>     
                    </div>
                </div>    

                <div  className={Styles.divNewsContainer}>
                    <div className={Styles.divNewsMainImage}>
                        <img className={Styles.imgMainNewsCarrousel} alt="img news" data-rjs="2" src="images/gestionfit20231012.png"></img> 
                    </div>               
                    <div className={Styles.divNewsMainContent}>
                        <p>
                        {t("news.gestionFitContentP1")}
                        </p>
                        <p>
                        {t("news.viewMore")}<Link className={Styles.buttonDetails} to={"/fitness_empresarial_gestion_fitness"}>{t("general.viewMore")}</Link>
                        </p>     
                    </div>
                </div>
            
                <hr></hr>    
                <p>
                    &nbsp;
                </p>
                <p>
                 <Link className={Styles.buttonContact} to={"/contact"}>{t("general.contactUs")}</Link>
                </p>                      
            </div>
            <div className="divPageTop">   
            </div>            
        </div>
    )
}

export default News