import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';

import AboutUs from './pages/aboutus';
import Colombia from './pages/colombia';
import Contact from './pages/contact';
import DigitalTransformation from './pages/digital_transformation';
import Home from './pages/home';
import News from './pages/news';
import NewsFitnessEmpresarialGestionFitness from './pages/fitness_empresarial_gestion_fitness'
import Peru from './pages/peru';
import Products from './pages/products';
import SoftwareDevelopment from './pages/software_development';
import SoftwareFit from './pages/software_fit';
import SoftwarePOS from './pages/software_pos';
import EverDream from './pages/ever_dream_of_an_app_that_solves_your_problem';
import StuckInATechRut from './pages/stuck_in_a_tech_rut';
import Everwonder from './pages/ever_wonder__who_brings_your_favorite_apps_to_life';
import Usa from './pages/usa';

import {
  createBrowserRouter,
  RouterProvider,
} from 'react-router-dom';

import './i18n';


const router = createBrowserRouter([
  {
    path: "/",
    element: <Home/>
  },
  {
    path: 'aboutus',
    element: <AboutUs/>
  },   
  {
    path: 'colombia',
    element: <Colombia/>
  }, 
  {
    path: 'contact',
    element: <Contact/>
  }, 
  {
    path: 'contacto',
    element: <Contact/>
  },   
  {
    path: 'digital_transformation',
    element: <DigitalTransformation/>
  },       
  {
    path: 'home',
    element: <Home/>
  },  
  {
    path: 'news',
    element: <News/>
  },    
  {
    path: 'fitness_empresarial_gestion_fitness',
    element: <NewsFitnessEmpresarialGestionFitness/>
  },   
  {
    path: 'peru',
    element: <Peru/>
  }, 
  {
    path: 'products',
    element: <Products/>
  },  
  {
    path: 'productos',
    element: <Products/>
  }, 
  {
    path: 'servicios',
    element: <Products/>
  },   
  {
    path: 'software_development',
    element: <SoftwareDevelopment/>
  },    
  {
    path: 'software_pos',
    element: <SoftwarePOS/>
  },       
  {   
    path: 'software_fit',
    element: <SoftwareFit/>
  },   
  {
    path: 'software_pos',
    element: <SoftwarePOS/>
  }, 
  {
    path: 'ever_dream_of_an_app_that_solves_your_problem',
    element: <EverDream/>
  },   
  {
    path: 'ever_wonder__who_brings_your_favorite_apps_to_life',
    element: <Everwonder/>
  },     
  {
    path: 'stuck_in_a_tech_rut',
    element: <StuckInATechRut/>
  },   
  {
    path: 'support',
    element: <Contact/>
  },   
  {
    path: 'soporte',
    element: <Contact/>
  },   
  {
    path: 'usa',
    element: <Usa/>
  }, 
])

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

root.render(
  <React.StrictMode>
    <RouterProvider router={router} />
  </React.StrictMode>
);
