import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import {useTranslation} from 'react-i18next'

const NavBar = () => {

  const {t, i18n} = useTranslation();

  useEffect(() => {
      const lng = "es";//navigator.language;
      i18n.changeLanguage(lng);
    }, [])  

    return (
        <section>
        <nav className="NavBar_Compl navbar navbar-default navbar-fixed-top navbar-fullclean">
          <div className="container">
            <div className="navbar-header">
              <button id="burgerMenu" aria-expanded="false" className="navbar-toggle collapsed" data-target="#washos-navbar-collapse" data-toggle="collapse" type="button">
                <span className="sr-only">Toggle navigation</span>
                <span className="icon-bar"></span>
                <span className="icon-bar"></span>
                <span className="icon-bar"></span>
              </button>
              <Link className="navbar-brand" to="/home">
                <img className="logoHeader" alt="digitaltrip.net - logo" data-rjs="2" src="images/logo_digitaltrip.png"></img> 
              </Link>
            </div>
            <div className="collapse navbar-collapse" id="washos-navbar-collapse">
              <ul className="nav navbar-nav">
                <li>
                    <Link to="/aboutus">{t("menu.aboutUs")}</Link>
                </li>
                <li>
                    <Link to="/products">{t("menu.products")}</Link>
                </li>              
                <li>
                    <Link to="/news">{t("menu.news")}</Link>
                </li>
              </ul>
              <div className="navbar-right navbar-buttons">
                <Link to="/contact" className="btn btn-default navbar-btn btn-login right-buttons">{t("menu.contact")}</Link>
              </div>
            </div>
          </div>
        </nav>   
      </section>
    );
}

export default NavBar;