import React, { useEffect } from "react";
import { Link } from 'react-router-dom';
import {useTranslation} from 'react-i18next'

import NavBar from "../components/NavBar/Navbar";
import Styles from "../components/Styles/Styles.module.css"; 
import TabTitle from "../components/Utilities/FunctionTitle";

function Products() {

    const {t, i18n} = useTranslation();

    useEffect(() => {
        const lng = navigator.language;
        i18n.changeLanguage(lng);
      }, [])   

    TabTitle("Products | digitaltrip.net | Boost your business");

    return (
        <div>
            <NavBar></NavBar>     
            <div className="divPageTop">    
            </div>
            <div className={Styles.divContent}>
            <h1>
            {t("products.title")}
                </h1>
                <p>
                {t("products.p1")}
                </p>
            <h1>{t("products.titleDigitalTransformation")}</h1>
                <p>
                {t("products.p1DigitalTransformation")}
                </p>     
                <p>
                <Link className={Styles.buttonDetails} to={"/digital_transformation"}>{t("general.viewMore")}</Link> 
                </p>      
                <br></br>             
                <h1>{t("products.titleSoftwareFit")}</h1>
                <p>
                {t("products.p1SoftwareFit")}
                </p>
                <p>
                 <Link className={Styles.buttonDetails} to={"/software_fit"}>{t("general.viewMore")}</Link>
                </p>  
                <br></br>                    
                <h1>{t("products.titleSoftwarePos")}</h1>
                <p>
                {t("products.p1SoftwarePos")}
                </p>   
                <p>
                <Link className={Styles.buttonDetails} to={"/software_pos"}>{t("general.viewMore")}</Link>
                </p>       
                <br></br>                   
                <h1>{t("products.titleSoftwareDevelopment")}</h1>
                <p>
                {t("products.p1SoftwareDevelopment")}
                </p>                    
                <p>
                <Link className={Styles.buttonDetails} to={"/software_development"}>{t("general.viewMore")}</Link>
                </p>   
                <br></br>   
                <p>&nbsp;</p>  
                <p>
                 <Link className={Styles.buttonContact} to={"/contact"}>{t("general.contactUs")}</Link>
                </p>                                                                  
            </div>
            <div className="divPageTop">   
            </div>            
        </div>
    )
}

export default Products