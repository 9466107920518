import React, { useEffect } from "react";
import { Link } from 'react-router-dom';
import {useTranslation} from 'react-i18next'

import NavBar from "../components/NavBar/Navbar";
import Styles from "../components/Styles/Styles.module.css"; 
import TabTitle from "../components/Utilities/FunctionTitle";

function StuckInATechRut() {

    const {t, i18n} = useTranslation();

    useEffect(() => {
        const lng = navigator.language;
        i18n.changeLanguage(lng);
      }, [])   

    TabTitle("Stuck in a tech rut? Your dream app or process improvement feels out of reach? | news | digitaltrip.net | Boost your business");

    return (
        <div>
            <NavBar></NavBar>     
            <div className="divPageTop">    
            </div>
            <div className={Styles.divContentLarge}>
                <h2>{t("news.post0219Title")}</h2>                     
                <div  className={Styles.divNewsContainer}>
                    <div className={Styles.divNewsMainImage}>
                        <div className={Styles.divNewsMainImage}>
                            <video autoPlay controls muted loop className={Styles.videoMainNews}>
                                <source src="videos/stuck_in_a_tech_rut.mp4" type="video/mp4" />
                            </video >                             
                        </div>                            

                    </div>               
                    <div className={Styles.divNewsMainContent}>
                        <p>
                        {t("news.post0219ContentP1")}
                        </p>
                        <p>
                        {t("news.post0219ContentP2")}
                        </p>       
                        <p>
                        {t("news.post0219ContentP3")}
                        </p>             
                        <p>
                        {t("news.post0219ContentP4")}
                        </p>      
                        <p>
                        {t("news.post0219ContentP5")}
                        </p>                                                 
                    </div>
                </div>
                <p>
                    &nbsp;
                </p>
                <p>
                {t("news.viewAll")}<Link className={Styles.buttonDetails} to={"/news"}>{t("general.here")}</Link>
                </p>  
                <p>&nbsp;</p>  
                <p>
                 <Link className={Styles.buttonContact} to={"/contact"}>{t("general.contactUs")}</Link>
                </p>                    
            </div>
            <div className="divPageTop">   
            </div>            
        </div>
    )
}

export default StuckInATechRut