import React, { useEffect } from "react";
import { Link } from 'react-router-dom';
import {useTranslation} from 'react-i18next'

import NavBar from "../components/NavBar/Navbar";
import Styles from "../components/Styles/Styles.module.css"; 
import TabTitle from "../components/Utilities/FunctionTitle";

function DigitalTransformation() {
    
    const {t, i18n} = useTranslation();

    useEffect(() => {
        const lng = navigator.language;
        i18n.changeLanguage(lng);
      }, [])   

    TabTitle("Digital Transformation | digitaltrip.net | Boost your business");
    return (
        <div>
            <NavBar></NavBar>     
            <div className="divPageTop">    
            </div>
            <div className={Styles.divContent}>
                <h1>{t("products.titleDigitalTransformation")}</h1>
                <p>
                {t("productDigitalTransformation.p1")}
                </p>                     
                <p>
                {t("products.viewAll")}<Link className={Styles.buttonDetails} to={"/products"}>{t("general.here")}</Link>
                </p>    
                <p>&nbsp;</p>  
                <p>
                 <Link className={Styles.buttonContact} to={"/contact"}>{t("general.contactUs")}</Link>
                </p>                             
            </div>
            <div className="divPageTop">   
            </div>            
        </div>
    )
}

export default DigitalTransformation