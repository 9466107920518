import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';

i18n
.use(LanguageDetector)
.use(initReactI18next)
.init({
    debug: true,
    fallbackLng: 'en',
    supportedLngs: ["en", "es"],
    interpolation: {
        escapeValue: false,        
    }, 
    resources: {
        en:{
            translation:{
                aboutUs:{
                    title: "About us",
                    p1: "Our proposal is to build on our extensive experience with digital transformation strategies, technology adoption, and business process improvement to encourage the implementation of innovative methods that optimize the operations of small-to-medium-sized enterprises (SMEs) in the United States to increase their profitability and to advance their digital transformation.",
                    p2: "Small businesses require digital transformation to stay competitive in today's fast-paced business world. Large companies continue to enhance their processes and services regularly, and it is crucial for small businesses to have the same opportunities to compete under similar conditions. Drawing on my experience, We are committed to bridging this gap by reaching out to small businesses, understanding their unique needs, and working collaboratively with them to implement the most optimal solution.",
                    p3: "Sebastian Ochoa is our Subject Matter Expert (SME) who has more than 23 years of experience in information technology applied to different types of international companies. Our first approach is to have a diagnosis of your digital transformation needs and with this we deliver a complete plan for the implementation and adaptation of the best technology to suit you.",
                    info: "If you want to know our information in: ",
                
                },     
                infoColombia:{
                    title: "digitaltrip.net in Colombia",
                    p1: "Company name: ---",
                    p2: "TIN/ Nit: 8162461",
                    p3: "Banking saving account Bancolombia # 20617046467"
                },     
                infoPeru:{
                    title: "digitaltrip.net in Peru",
                    p1: "Partnership with www.gestionfit.com",
                },                    
                infoUsa:{
                    title: "digitaltrip.net in the United States of America",
                    p1: "Company name: Digital trip LLC",
                },            
                general:{
                    contactUs: "Contact us",
                    here: "here",
                    viewMore: "View more",
                    peru: "Peru",
                    usa: "United States of America"
                },                
                greeting:{
                    hello:"Hello world"
                },
                contact:{
                    address: "Address",
                    phone: "Phone",
                    title: "Contact for sales and information",
                    titleRemoteConnection: "Remote connection",
                    titleRemoteConnectionDownload: "Download",
                    titleTechnical: "Contact for technical support"
                },
                home:{
                    title:"We help your business on the trip to digital transformation",
                    p1:"Our proven method in multiple businesses will allow you to identify opportunities in your business to implement the technology that will help you focus on your business, controlling it better and with tools that help you grow.",
                    p2:"We want to accompany you in this process so that you can take your business to the next level.",
                    p3:"Let's contact so that we can make a diagnosis and with this an effective and affordable plan for the digital transformation that you need",
                    p4:"If you want to know all our products, you can click"
                },
                main:{
                    digitalTransformation: "Digital transformation"
                },
                menu:{
                    aboutUs: "About us",
                    contact: "Contact & Support",
                    products: "Products & Services",
                    news: "News"
                },
                news:{
                    title: "News",
                    p1: "Find out about our news here",                


                    gestionFitTitle: "Fitness Empresarial - gestionfit.com, Oct. 12th, 2023",
                    gestionFitContentP1: "In this publication of the magazine “Fitness Empresarial” published by the company Gestión Fitness, an interview is done with Sebastian Ochoa about the evolution of technology applied to fitness. ",
                    gestionFitContentP1Link: "The article can be seen ",
                    gestionFitContentP2: "In this he tells us about his career in the field of information technologies and how small and medium-sized companies can effectively benefit from modern technologies, accompanied by experts who can find the best value ratio.",
                    gestionFitContentP3: "He also tells us how technology has evolved and how you can take advantage of artificial intelligence tools to operate your business better, sell more and have better customer service.",
                    gestionFitContentP4: "Sebastián Ochoa is a computer engineer and technology management specialist with more than 20 years of experience in different areas, in addition to multiple custom software developments in different sectors such as fitness, health, beauty, retail and hospitality in countries like Colombia. , Peru, Chile, Spain and the United States. You can see his full profile ",
                    gestionFitContentP5: "We recommend that you read the complete “Fitness Empresarial” magazine and follow all the news in the fitness market "                                        ,

                    post0219Title: "Stuck in a tech rut? Your dream app or process improvement feels out of reach?",
                    post0219ContentP1: "Tired of clunky software slowing you down? We craft custom solutions that boost efficiency & joy. It's achievable! ✅",
                    post0219ContentP2: "Our clients achieve amazing results with our help! ✨ . Our team thrives on turning ideas into reality . Let's unlock your potential together! ✨ Get a FREE consultation & bring your vision to life. ",
                    post0219ContentP3: "We believe in the power of tech to empower & elevate. Partner with us and watch your business soar!",
                    post0219ContentP4: "www.digitaltrip.net",
                    post0219ContentP5: "#softwaredevelopment #customsoftware #appdevelopment #processoptimization #businessautomation #smallbusiness #entrepreneur #innovation #technology #growyourbusiness #efficiency #customersatisfaction #testimonial #casestudy #freeconsultation #startup #collaboration #florida (if applicable) #supportlocal #futureproof #digitaltransformation #cloudcomputing #achieveyourgoals #makeadifference #letsgo",

                    post0220Title: "Ever dream of an app that solves YOUR problem? But software development seems like a mystery? We're here to help! ✨ ",
                    post0220ContentP1: "This 5-page guide breaks down the process into simple steps, so you can turn your idea into reality! 🧐",
                    post0220ContentP2: "Step 1: Define Your Vision! What problem does your app solve? Who are your users? Let's brainstorm and map out your app's core features and functionalities. 🚀",
                    post0220ContentP3: "Step 2: Design & Planning! Wireframes, mockups, and prototypes bring your vision to life! ️ Our team translates your ideas into a user-friendly and intuitive design. 🚇",
                    post0220ContentP4: "Step 3: Development & Testing! ‍ Our expert developers bring your app to life, line by line! ️‍♀️ Rigorous testing ensures smooth functionality and a bug-free experience. 📢",
                    post0220ContentP5: "Step 4: Launch & Support! Your app is live! We offer ongoing support and maintenance to ensure your app's success. 🏅",
                    post0220ContentP6: "www.digitaltrip.net",
                    post0220ContentP7: "#softwaredevelopment #appdevelopment #startup #ideatobusiness #createapp #buildyourapp #nontechnical #easyguide #entrepreneurlife #businessowner #makeadifference #techforgood #wemakeittappening #followfortips #yourdreamyourway #startyourownbusiness #innovation #futureoftech #creativitycounts #letsbuildsomething #yourvisionourtech #dreamscometrue #makeappmagic #starthere #defineyourvision #problemtoapp #brainstorming #userfocused #appfeatures #functionalityfirst #getclearonthegoal #letschat #commentbelow #shareyourideas #buildsomethingcool #startaconversation #designthinking #wireframing #prototyping #userexperience #userinterface #designmatters #makeitilookgood #buildwithus #collaborationiskey #yourinputmatters #dreamteam #letsmakeaplan #developmentmagic #codinglife #softwareengineering #testingmatters #qualityfirst #bugfreeguarantee #expertisespeaks #bringyourideatolife #techsolutions #yourvisioncoded #askusanything #launchday #appsuccess #ongoingsupport #wegotyourback #happyusers #makeadifference #yourdreamourmission #getstartedtoday #contactus #buildyourappnow #letsgo",

                    post0221Title: "Ever wonder who brings your favorite apps to life?  🤔",
                    post0221ContentP1: "Meet the unsung heroes of software development and discover how they work together to build your dream app! 🤯",
                    post0221ContentP2: "The Visionary Product Manager 🧐: They translate your needs into a clear vision, ensuring your app solves the right problem! Think of them as the storyteller and architect of your app.",
                    post0221ContentP3: "The UX/UI Artist Designer 🤩: They turn your vision into stunning visuals! They craft user interfaces that are both beautiful and intuitive, making your app a joy to use.",
                    post0221ContentP4: "The Code Wizards Developers 🥸: They translate designs into functioning code, bringing your app to life line by line! They possess magical powers (aka coding skills) to make your vision a reality.",
                    post0221ContentP5: "The Quality Guardian Tester 🤬: They ensure your app works flawlessly! They test every corner and click, identifying and fixing bugs to deliver a seamless and enjoyable experience.",
                    post0221ContentP6: "Ready to build your dream app with our team of superheroes? Contact us today for a free consultation! 🤑",
                    post0221ContentP7: "www.digitaltrip.net",
                    post0221ContentP8: "#testingmatters #qualityassurance #bugfreeguarantee #smoothperfomance #userhappiness #makeadifference #yourdreamourmission #getstartedtoday #contactus #buildyourappnow #letsgo #developersquad #codingmagic #softwareengineering #bringyourideatolife #teambonding #creativityandlogic #yourvisioncoded #askusanything #buildwithus #techsolutions #designerlife #uxui #visuallanguage #userinterface #userexperience #makeappealing #intuitiveapps #designmatters #dreamteammember #yourinputmatters #productmanager #visionaryleader #userstories #solvetheproblem #apparchitecture #yourneedsfirst #shareyourstory #letscollaborate #buildsomethingmeaningful #startaconversation #softwaredevelopment #appdevelopment #dreamteam #buildyourapp #rolesrevealed #unsungheroes #teamingup #createapp #nontechnical #makeadifference #techforgood #wemakeittappening #followfortips #yourvisionourtech #dreamscometrue #makeappmagic #starthere",                    

                    viewAll: "If you want to know all our news, you can click ",
                    viewMore: "If you want to see the full news, you can click "
                },
                products:{
                    title: "Our products & services",
                    p1: "Our company specializes in providing digital transformation services tailored to meet the unique needs of small businesses. These services will include:",
                    titleDigitalTransformation: "Digital transformation",
                    p1DigitalTransformation: "We identify the needs of small businesses and help them adapt the most effective technology that helps them operate their business better, sell more and grow.",
                    titleSoftwareFit: "Software Fit",
                    p1SoftwareFit: "Software for gyms",
                    titleSoftwarePos: "Software POS (Point of sale)",
                    p1SoftwarePos: "Software for restaurants, retail, bars",
                    titleSoftwareDevelopment: "Software Development",
                    p1SoftwareDevelopment: "We turn your ideas into web software applications, mobile applications and other technologies",                    
                    viewAll: "If you want to know all our products, you can click ",
                },
                productDigitalTransformation:{
                    p1: "We identify the needs of small businesses and help them adapt the most effective technology that helps them operate their business better, sell more and grow.                ",
                },                                                 
                productSoftwareFit:{
                    p1: "Software for gyms",
                },
                productSoftwarePOS:{
                    p1: "Software for restaurants, retail, bars",
                },
                productSoftwareDevelopment:{
                    p1: "We turn your ideas into web software applications, mobile applications and other technologies",
                },  
                support: {
                    knowledgeTitle: "Knowledge base",
                    youtube: "You can watch our training and support videos on our YouTube channel by clicking here",
                }
            }
        },
        es:{
            translation:{
                aboutUs:{
                    title: "Acerca de nosotros",
                    p1: "Nuestra propuesta es aprovechar nuestra amplia experiencia en estrategias de transformación digital, adopción de tecnología y mejora de procesos comerciales para fomentar la implementación de métodos innovadores que optimicen las operaciones de las pequeñas y medianas empresas (PYMES) en los Estados Unidos para aumentar su rentabilidad y avanzar en su transformación digital.",
                    p2: "Las pequeñas empresas necesitan una transformación digital para seguir siendo competitivas en el acelerado mundo empresarial actual. Las grandes empresas continúan mejorando sus procesos y servicios con regularidad, y es crucial que las pequeñas empresas tengan las mismas oportunidades para competir en condiciones similares. Basándonos en mi experiencia, estamos comprometidos a cerrar esta brecha acercándonos a las pequeñas empresas, entendiendo sus necesidades únicas y trabajando en colaboración con ellas para implementar la solución más óptima.",
                    p3: "Sebastián Ochoa es nuestro Subject Matter Expert (SME) que cuenta con más de 23 años de experiencia en tecnologías de la información aplicadas a diferentes tipos de empresas internacionales. Nuestro primer acercamiento es tener un diagnóstico de tus necesidades de transformación digital y con ello entregamos un plan completo de implementación y adaptación de la mejor tecnología a tu medida.",
                    info: "Si quieres conocer nuestra información en:",
                },     
                infoColombia:{
                    title: "digitaltrip.net en Colombia",
                    p1: "Razón social: ---",
                    p2: "Nit: 8162461",
                    p3: "Cuenta de ahorros Bancolombia No. 20617046467"
                },    
                infoPeru:{
                    title: "digitaltrip.net en Perú",
                    p1: "Partnership con www.gestionfit.com",
                },                  
                infoUsa:{
                    title: "digitaltrip.net en los Estados Unidos de América",
                    p1: "Nombre de la compañía: Digital trip LLC",
                },                            
                general:{
                    contactUs: "Contáctanos",
                    here: "aquí",
                    viewMore: "Ver más",
                    peru: "Perú",
                    usa: "Estados Unidos de América"
                },
                greeting:{
                    hello:"Hola mundo"
                },
                contact:{
                    address: "Dirección",
                    phone: "Teléfono",
                    title: "Contacto para ventas e información ",
                    titleRemoteConnection: "Conexión remota",
                    titleRemoteConnectionDownload: "Descarga",
                    titleTechnical: "Contacto para soporte técnico"
                },                
                home:{
                    title:"Te ayudamos en tu viaje hacia la transformación digital",
                    p1:"Nuestro método probado en múltiples negocios te permitirá identificar oportunidades en tu negocio para implementar la tecnología que te ayudará a enfocarte en tu negocio, controlándolo mejor y con herramientas que te ayuden a crecer.",
                    p2:"Queremos acompañarte en este proceso para que puedas llevar tu negocio al siguiente nivel.",
                    p3:"Contactemos para que podamos hacer un diagnóstico y con ello un plan eficaz y asequible para la transformación digital que necesitas.",
                    p4:"Si quieres conocer todos nuestros productos, puedes hacer clic "                    
                }  ,
                main:{
                    digitalTransformation: "Transformación digital"
                },
                menu:{
                    aboutUs: "Acerca de nosotros",
                    contact: "Contacto & Soporte",
                    products: "Productos & Servicios",
                    news: "Novedades"
                },
                news:{
                    title: "Novedades",
                    p1: "Entérate de nuestras novedades aquí",
                    gestionFitTitle: "Fitness empresarial - gestionfit.com, Oct. 12, 2023",
                    gestionFitContentP1: "En esta publicación de la revista “Fitness Empresarial” publicada por la compañía Gestión Fitness, se hace una entrevista a Sebastian Ochoa sobre la evolución de la tecnología aplicada al fitness. ",
                    gestionFitContentP1Link: "El artículo se puede ver ",
                    gestionFitContentP2: "En esta nos cuenta su trayectoria en el campo de las tecnologías de información y cómo las pequeñas y medianas empresas pueden beneficiarse de forma efectiva de las tecnologías modernas, acompañados de expertos que puedan encontrar la mejor relación de valor.",
                    gestionFitContentP3: "También nos cuenta cómo ha evolucionado la tecnología y cómo se puede sacar provecho de herramientas de inteligencia artificial para poder operar mejor su negocio, venda más y tenga mejor servicio a su cliente.",
                    gestionFitContentP4: "Sebastián Ochoa es un Ingeniero informático y especialista en gerencia de tecnología con más de 20 años de experiencia en diferentes áreas, además de múltiples desarrollos de software a la medida en diferentes sectores como el fitness, salud, belleza, retail y hostelería en países como Colombia, Perú, Chile, España y Estados Unidos. Puedes ver su perfil completo ",
                    gestionFitContentP5: "Te recomendamos leer la revista “Fitness Empresarial” completa y seguir todas las novedades del mercado fitness ",

                    post0219Title: "¿Atrapado en una rutina tecnológica? ¿La aplicación de tus sueños o la mejora de procesos se sienten fuera de tu alcance?",
                    post0219ContentP1: "¿Estás cansado de que el software torpe te ralentice? Creamos soluciones personalizadas que aumentan la eficiencia y la alegría. ¡Es alcanzable! ✅",
                    post0219ContentP2: "¡Nuestros clientes logran resultados sorprendentes con nuestra ayuda! ✨ . Nuestro equipo se esfuerza por convertir las ideas en realidad. ¡Desbloqueemos su potencial juntos! ✨ Obtenga una consulta GRATUITA y haga realidad su visión.",
                    post0219ContentP3: "Creemos en el poder de la tecnología para empoderar y elevar. ¡Asóciese con nosotros y observe cómo su negocio se dispara!",
                    post0219ContentP4: "www.digitaltrip.net",
                    post0219ContentP5: "#desarrollodesoftware #softwarepersonalizado #desarrollodeaplicaciones #optimizaciondeprocesos #automatizaciondenegocios #pequeñasempresas #emprendimiento #innovacion #tecnologia #crecetunegocio #eficiencia #satisfacciondeclientes #testimonios #casosdeestudio #consultagratis #startup #colaboracion #florida #apoyalocal #pruebafuturo #transformacionndigital #computacionenlanube #alcanzatusmetas #hacerdiferencia #vamos",                    

                    post0220Title: "¿Alguna vez soñaste con una aplicación que resuelva TU problema? ¿Pero el desarrollo de software parece un misterio? ¡Estamos aquí para ayudar! ✨",
                    post0220ContentP1: "¡Esta guía de 5 páginas divide el proceso en pasos simples para que puedas convertir tu idea en realidad! 🧐",
                    post0220ContentP2: "Paso 1: ¡Define tu visión! ¿Qué problema resuelve tu aplicación? ¿Quiénes son sus usuarios? Hagamos una lluvia de ideas y tracemos un mapa de las características y funcionalidades principales de su aplicación. 🚀",
                    post0220ContentP3: "Paso 2: ¡Diseño y planificación! ¡Los wireframes, las maquetas y los prototipos dan vida a tu visión! ️ Nuestro equipo traduce sus ideas en un diseño intuitivo y fácil de usar. 🚇",
                    post0220ContentP4: "Paso 3: ¡Desarrollo y pruebas! ‍ ¡Nuestros desarrolladores expertos dan vida a tu aplicación, línea por línea! ️‍♀️ Las pruebas rigurosas garantizan una funcionalidad fluida y una experiencia libre de errores. 📢",
                    post0220ContentP5: "Paso 4: ¡Lanzamiento y soporte! ¡Tu aplicación está activa! Ofrecemos soporte y mantenimiento continuos para garantizar el éxito de su aplicación. 🏅",
                    post0220ContentP6: "www.digitaltrip.net",
                    post0220ContentP7: "#softwaredevelopment #appdevelopment #startup #ideatobusiness #createapp #buildyourapp #nontechnical #easyguide #entrepreneurlife #businessowner #makeadifference #techforgood #wemakeittappening #followfortips #yourdreamyourway #startyourownbusiness #innovation #futureoftech #creativitycounts #letsbuildsomething #yourvisionourtech #dreamscometrue #makeappmagic #starthere #defineyourvision #problemtoapp #brainstorming #userfocused #appfeatures #functionalityfirst #getclearonthegoal #letschat #commentbelow #shareyourideas #buildsomethingcool #startaconversation #designthinking #wireframing #prototyping #userexperience #userinterface #designmatters #makeitilookgood #buildwithus #collaborationiskey #yourinputmatters #dreamteam #letsmakeaplan #developmentmagic #codinglife #softwareengineering #testingmatters #qualityfirst #bugfreeguarantee #expertisespeaks #bringyourideatolife #techsolutions #yourvisioncoded #askusanything #launchday #appsuccess #ongoingsupport #wegotyourback #happyusers #makeadifference #yourdreamourmission #getstartedtoday #contactus #buildyourappnow #letsgo",

                    post0221Title: "¿Alguna vez te preguntaste quién da vida a tus aplicaciones favoritas? 🤔",
                    post0221ContentP1: "¡Conozca a los héroes anónimos del desarrollo de software y descubra cómo trabajan juntos para crear la aplicación de sus sueños! 🤯",
                    post0221ContentP2: "El gerente de producto visionario 🧐: traducen sus necesidades en una visión clara, ¡garantizando que su aplicación resuelva el problema correcto! Piensa en ellos como los narradores y arquitectos de tu aplicación.",
                    post0221ContentP3: "El artista diseñador UX/UI 🤩: ¡Convierten tu visión en imágenes impresionantes! Crean interfaces de usuario que son hermosas e intuitivas, lo que hace que usar tu aplicación sea un placer.",
                    post0221ContentP4: "Los desarrolladores magos: traducen diseños en código funcional, ¡dando vida a su aplicación línea por línea! Poseen poderes mágicos (también conocidos como habilidades de codificación) para hacer realidad su visión.",
                    post0221ContentP5: "El probador guardián de la calidad 🤬: ¡Se aseguran de que su aplicación funcione perfectamente! Prueban cada rincón y hacen clic, identifican y corrigen errores para ofrecer una experiencia fluida y agradable.",
                    post0221ContentP6: "¿Listo para crear la aplicación de tus sueños con nuestro equipo de superhéroes? ¡Póngase en contacto con nosotros hoy para una consulta gratuita! 🤑",
                    post0221ContentP7: "www.digitaltrip.net",
                    post0221ContentP8: "#testingmatters #qualityassurance #bugfreeguarantee #smoothperfomance #userhappiness #makeadifference #yourdreamourmission #getstartedtoday #contactus #buildyourappnow #letsgo #developersquad #codingmagic #softwareengineering #bringyourideatolife #teambonding #creativityandlogic #yourvisioncoded #askusanything #buildwithus #techsolutions #designerlife #uxui #visuallanguage #userinterface #userexperience #makeappealing #intuitiveapps #designmatters #dreamteammember #yourinputmatters #productmanager #visionaryleader #userstories #solvetheproblem #apparchitecture #yourneedsfirst #shareyourstory #letscollaborate #buildsomethingmeaningful #startaconversation #softwaredevelopment #appdevelopment #dreamteam #buildyourapp #rolesrevealed #unsungheroes #teamingup #createapp #nontechnical #makeadifference #techforgood #wemakeittappening #followfortips #yourvisionourtech #dreamscometrue #makeappmagic #starthere",                    

                    viewAll: "Si quieres conocer todas nuestras novedades, puedes hacer clic ",
                    viewMore: "Si quieres ver la noticia completa, puedes hacer clic "
                },
                products:{
                    title: "Nuestros productos & servicios",
                    p1: "Nuestra empresa se especializa en brindar servicios de transformación digital diseñados para satisfacer las necesidades únicas de las pequeñas empresas. Estos servicios incluirán:",
                    titleDigitalTransformation: "Transformación digital",
                    p1DigitalTransformation: "Identificamos las necesidades de las pequeñas empresas y las ayudamos a adaptar la tecnología más eficaz que les ayude a operar mejor su negocio, vender más y crecer.",
                    titleSoftwareFit: "Software Fit",
                    p1SoftwareFit: "Software para gimnasios",
                    titleSoftwarePos: "Software POS (Punto de venta)",
                    p1SoftwarePos: "Software para restaurantes, comercio minorista, bares.",
                    titleSoftwareDevelopment: "Desarrollo de software a la medida",
                    p1SoftwareDevelopment: "Convertimos tus ideas en aplicaciones de software web, aplicaciones móviles y otras tecnologías.",                    
                    viewAll: "Si quieres conocer todos nuestros productos, puedes hacer clic",
                },
                productDigitalTransformation:{
                    p1: "Identificamos las necesidades de las pequeñas empresas y las ayudamos a adaptar la tecnología más eficaz que les ayude a operar mejor su negocio, vender más y crecer.                ",
                },                                                 
                productSoftwareFit:{
                    p1: "Software para gimnasios",
                },
                productSoftwarePOS:{
                    p1: "Software para restaurantes, comercio minorista, bares.",
                },
                productSoftwareDevelopment:{
                    p1: "Convertimos tus ideas en aplicaciones de software web, aplicaciones móviles y otras tecnologías.",
                },  
                support: {
                    knowledgeTitle: "Base de conocimiento",
                    youtube: "Puedes ver nuestros vídeos de formación y soporte en nuestro canal de YouTube haciendo clic aquí",
                }                                       
            }
        }        
    }
});

export default i18n;