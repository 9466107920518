import React, { useEffect } from "react";
import { Link } from 'react-router-dom';
import {useTranslation} from 'react-i18next'

import NavBar from "../components/NavBar/Navbar";
import Styles from "../components/Styles/Styles.module.css"; 
import TabTitle from "../components/Utilities/FunctionTitle";

function AboutUs() {

    const {t, i18n} = useTranslation();

    useEffect(() => {
        const lng = navigator.language;
        i18n.changeLanguage(lng);
      }, [])   
      
      
    TabTitle("About us | digitaltrip.net | Boost your business");
    return (
        <div>
            <NavBar></NavBar>     
            <div className="divPageTop">    
            </div>
            <div className={Styles.divContent}>
                <h1>
                {t("aboutUs.title")}
                </h1>
                <p>
                {t("aboutUs.p1")}
                </p>
                <p>
                {t("aboutUs.p2")}
                </p>
                <p>
                {t("aboutUs.p3")}
                </p>

                <p>
                {t("aboutUs.info")}             
                </p>                    
                <ul>
                    <li>
                        Colombia: <Link className={Styles.buttonDetails} to={"/colombia"}>{t("general.viewMore")}</Link>
                    </li>
                    &nbsp;
                    <li>
                        {t("general.usa")}:&nbsp; <Link className={Styles.buttonDetails} to={"/usa"}>{t("general.viewMore")}</Link>
                    </li>        
                    &nbsp;
                    <li>
                        {t("general.peru")}:&nbsp; <Link className={Styles.buttonDetails} to={"/peru"}>{t("general.viewMore")}</Link>
                    </li>                                      
                </ul>

                <p>&nbsp;</p>  
                <p>
                 <Link className={Styles.buttonContact} to={"/contact"}>{t("general.contactUs")}</Link>
                </p>                   
            </div>
            <div className="divPageTop">   
            </div>            
        </div>
    )
}

export default AboutUs