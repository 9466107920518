import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import {useTranslation} from 'react-i18next'

import NavBar from "../components/NavBar/Navbar";
import Styles from "../components/Styles/Styles.module.css"; 
import TabTitle from "../components/Utilities/FunctionTitle";

function Home(){

    const {t, i18n} = useTranslation();

    useEffect(() => {
        const lng = navigator.language;
        i18n.changeLanguage(lng);
      }, [])   

    TabTitle("digitaltrip.net | software | fit | pos | digital transformation | Boost your business");

    return (
        <div>
            <NavBar></NavBar>
            <div className="divPageTop">    
            </div>
            <div className={Styles.divContent}>
                <h1>{t("home.title")}
                </h1>
                <p>
                {t("home.p1")}
                </p>
                <p>
                {t("home.p2")}
                </p>
                <p>
                {t("home.p3")}
                </p>               
                <p>
                {t("home.p4")} <Link className={Styles.buttonDetails} to={"/products"}>{t("general.here")}</Link>
                </p>  
                <p>&nbsp;</p>  
                <p>
                 <Link className={Styles.buttonContact} to={"/contact"}>{t("general.contactUs")}</Link>
                </p>         
                <div className={Styles.divContainerL1}>
                    <div className={Styles.divContainerL2}>
                        <div className={Styles.divContainerL3}>
                            <div className={Styles.divContainerL4}>
                                <div className={Styles.divContainerL5}>
                                    <div className={Styles.divContainerMainImage}>
                                        <img className={Styles.imgMainNewsCarrousel} alt="img news" data-rjs="2" src="videos/roles.png"></img> 
                                    </div>    
                                    <div className={Styles.divContainerMainText}>
                                        <p>
                                            {t("news.post0221Title")}
                                        </p>
                                        <p>
                                            <Link className={Styles.buttonDetails} to={"/ever_wonder__who_brings_your_favorite_apps_to_life"}>{t("general.viewMore")}</Link>
                                        </p>     
                                    </div>                                       
                                </div>                                
                            </div>       
                            <div className={Styles.divContainerL4}>
                                <div className={Styles.divContainerL5}>
                                    <div className={Styles.divContainerMainImage}>
                                        <img className={Styles.imgMainNewsCarrousel} alt="img news" data-rjs="2" src="videos/dream_app_full.png"></img> 
                                    </div>   
                                    <div className={Styles.divContainerMainText}>
                                        <p>
                                            {t("news.post0220Title")}
                                        </p>
                                        <p>
                                            <Link className={Styles.buttonDetails} to={"/ever_dream_of_an_app_that_solves_your_problem"}>{t("general.viewMore")}</Link>
                                        </p>     
                                    </div>                                         
                                </div>                                
                            </div>                             
                            <div className={Styles.divContainerL4}>
                                <div className={Styles.divContainerL5}>
                                    <div className={Styles.divContainerMainImage}>
                                        <img className={Styles.imgMainNewsCarrousel} alt="img news" data-rjs="2" src="videos/stuck_in_a_tech_rut.png"></img> 
                                    </div>    
                                    <div className={Styles.divContainerMainText}>
                                        <p>
                                            {t("news.post0219ContentP1")}
                                        </p>
                                        <p>
                                            <Link className={Styles.buttonDetails} to={"/stuck_in_a_tech_rut"}>{t("general.viewMore")}</Link>
                                        </p>     
                                    </div>                                        
                                </div>                                
                            </div>  
                        </div>                                
                    </div>                                
                </div>                                
            </div>
            <div className="divPageTop">   
            </div>          
        </div>
        )
    }
    
    export default Home        
