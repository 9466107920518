import React, { useEffect } from "react";
import { Link } from 'react-router-dom';
import {useTranslation} from 'react-i18next'

import NavBar from "../components/NavBar/Navbar";
import Styles from "../components/Styles/Styles.module.css"; 
import TabTitle from "../components/Utilities/FunctionTitle";

function NewsFitnessEmpresarialGestionFitness() {

    const {t, i18n} = useTranslation();

    useEffect(() => {
        const lng = navigator.language;
        i18n.changeLanguage(lng);
      }, [])   

    TabTitle("Sebastian Ochoa, Fitness Empresarial, GestionFit.com  | news | digitaltrip.net | Boost your business");

    return (
        <div>
            <NavBar></NavBar>     
            <div className="divPageTop">    
            </div>
            <div className={Styles.divContentLarge}>
                <h2>{t("news.gestionFitTitle")}</h2>                     
                <div  className={Styles.divNewsContainer}>
                    <div className={Styles.divNewsMainImage}>
                    <   img className={Styles.imgMainNews} alt="img news" data-rjs="2" src="images/gestionfit20231012.png"></img> 
                    </div>               
                    <div className={Styles.divNewsMainContent}>
                        <p>
                        {t("news.gestionFitContentP1")}
                        {t("news.gestionFitContentP1Link")}
                        <a className={Styles.buttonDetails} href="https://issuu.com/gustavoyepes/docs/boleti_n_fitness_empresarial_no.18/s/37566279" target="_blank">{t("general.here")}</a> 
                        </p>
                        <p>
                        {t("news.gestionFitContentP2")}
                        </p>
                        <p>
                        {t("news.gestionFitContentP3")}
                        </p>
                        <p>
                        {t("news.gestionFitContentP4")}
                        <a className={Styles.buttonDetails} href="https://www.linkedin.com/in/sebastian8aalvarez/" target="_blank">{t("general.here")}</a> 
                        </p>
                        <p>
                        {t("news.gestionFitContentP5")}
                        <a className={Styles.buttonDetails} href="https://issuu.com/gustavoyepes/docs/boleti_n_fitness_empresarial_no.18?fr=xKAE9_zU1NQ" target="_blank">{t("general.here")}</a> 
                        </p>               
                    </div>
                </div>
                <p>
                    &nbsp;
                </p>
                <p>
                {t("news.viewAll")}<Link className={Styles.buttonDetails} to={"/news"}>{t("general.here")}</Link>
                </p>  
                <p>&nbsp;</p>  
                <p>
                 <Link className={Styles.buttonContact} to={"/contact"}>{t("general.contactUs")}</Link>
                </p>                    
            </div>
            <div className="divPageTop">   
            </div>            
        </div>
    )
}

export default NewsFitnessEmpresarialGestionFitness